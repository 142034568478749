import { noNullQuerySelector } from "../src/utils/dom";

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".course-card").forEach((card) => {
    const cardLink = noNullQuerySelector<HTMLAnchorElement>(
      ".learn-more-link",
      card,
    );

    card.addEventListener("click", () => {
      if (cardLink.dataset.clicked === "true") {
        cardLink.dataset.clicked = "false";
        return;
      }

      cardLink.click();
    });

    cardLink.addEventListener("click", (event) => {
      if (cardLink.dataset.clicked === "true") {
        event.preventDefault();
        event.stopPropagation();
      }
      cardLink.dataset.clicked = "true";
    });
  });
});
